<template>
  <div id="qualifications">
    <h2> Educational Qualifications</h2>
    <div class="inner">
      <div class="entry">
      <h3>Bachelor's in Engineering - Electronics And Telecommunication </h3>
      </div>
      <div class="entry">
      <h4>Savitribai Phule Pune University <strong>Aug 2017 - 2022</strong> </h4>
      </div>
      <div class="entry">
      <h4>GPA - 8.9/10</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Qualifications',
}
</script>

<style scoped>
.reader {
 width: 100%;
 padding: 30px 30px;
 border-radius: 0 0 2rem 0;
}

.entry {
  padding: 10px 0;
  border-bottom: #ccc 1px solid;
}

.inner {
  margin-top: 20px;
}

</style>
